import { subMonths, format } from "date-fns";

export default [
  {
    header: "Inicio",
    icon: "HomeIcon",
    route: "home",
  },
  {
    header: "Documentos",
    icon: "FileIcon",
    children: [
      {
        title: "Consulta Histórica",
        route: "documents",
        icon: "ClockIcon",
        action: "read",
        resource: "documents",
      },

      {
        title: "Agregar Documento",
        route: "documents-add",
        icon: "UploadIcon",
        action: "create",
        resource: "documents",
      },
    ],
  },
  {
    header: "Estadísticas",
    icon: "PieChartIcon",
    children: [
      {
        title: "Roedores",
        route: "reg-roadents-list",
        icon: "ListIcon",
        action: "update",
        resource: "stats",
      },
      {
        title: "Chagas",
        route: "reg-chagas-list",
        icon: "ListIcon",
        action: "update",
        resource: "stats",
      },
      {
        title: "Malaria",
        route: "reg-malaria-list",
        icon: "ListIcon",
        action: "update",
        resource: "stats",
      },
      {
        title: "Leishmaniasis",
        route: "reg-leishmaniasi-list",
        icon: "ListIcon",
        action: "update",
        resource: "stats",
      },
    ],
  },
];
