<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo">
              <div v-if="!$store.state.verticalMenu.isVerticalMenuCollapsed">
                <b-img
                        v-if="$store.state.appConfig.layout.skin ==='light'"
                        :src="appLogoImage"
                        alt="logo"
                />
                <b-img
                        v-else-if="$store.state.appConfig.layout.skin ==='dark'"
                        :src="appLogoImageDark"
                        alt="logo"
                />
              </div>
          </span>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appLogoImageDark } = $themeConfig.app
    return {
      appName,
      appLogoImage,
        appLogoImageDark
    }
  },
}
</script>

<style>

</style>
